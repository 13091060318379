import React, { useEffect, useState } from "react";
import AutoCompleteSearchBoxForReview from "../../components/AutoCompleteSearchBoxForReviews";
import StarRatings from "react-star-ratings";
import "./reviewSteps.scss";
import coin from "../../assets/images/coins.svg";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BsArrowRight, BsX, BsArrowLeft } from "react-icons/bs";
import country from "../../assets/images/country.png";
import address from "../../assets/images/address.png";
import thoughts from "../../assets/images/thoughts.png";

const items = [
  { id: 0, name: "Cobol", optionImg: country },
  { id: 1, name: "JavaScript", optionImg: country },
  { id: 2, name: "Basic", optionImg: country },
  { id: 3, name: "PHP", optionImg: country },
  { id: 4, name: "Java", optionImg: country },
];

const Step1 = ({
  attractionTypes,
  countries,
  reviews,
  handleReviewChange,
  handleChange,
  addReview,
  cancelLastReview,
  country,
}) => {
  const [isCurrentReviewFilled, setIsCurrentReviewFilled] = useState(false);
  const [isAttractionFind, setIsAttractionFind] = useState(false);

  // Function to check if the current review is filled out completely
  useEffect(() => {
    const currentReview = reviews[reviews.length - 1];
    const isFilled =
      currentReview.attraction_type &&
      currentReview.country &&
      currentReview.title &&
      currentReview.address &&
      currentReview.ratings;
    setIsCurrentReviewFilled(isFilled);
  }, [reviews]);
  const handleOnSelect = (index, item) => {
    console.log(item);
    handleChange(index, "country", item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <img
          src={item.flag_icon ? item.flag_icon : ""}
          style={{ width: "20px", height: "20px" }}
          onError={(e) => (e.target.src = item.flag_icon ? item.flag_icon : "")}
        />

        <span style={{ display: "block", textAlign: "left" }}>
          {item.name ? item.name : ""}
        </span>
      </>
    );
  };

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (reviews.length > 0) {
      setSearchValue(reviews[0]?.country.name);
    }
  }, [reviews]);

  const clearSearch = () => {
    setSearchValue("");
  };
  const renderErrorMessage = (error) => {
    return <div className="error-message">{error}</div>;
  };

  return (
    <div>
      {reviews &&
        reviews.map((review, index) => {
          return (
            <div key={index}>
              <div className="card-styling">
                <div className="stepper-search-input">
                  <label>
                    What is the attraction type you have visited?
                    {review.errors["attraction_type"] && (
                      <span className="error-asterisk">*</span>
                    )}
                  </label>
                  <div className="dropdown-container">
                    <select
                      className="dropdown"
                      value={review._id || review.attraction_type._id}
                      onChange={(e) =>
                        handleChange(
                          index,
                          "attraction_type",
                          attractionTypes.find(
                            (ele) => ele._id === e.target.value
                          )
                        )
                      }>
                      <option value="">Select an attraction</option>
                      {attractionTypes &&
                        attractionTypes.map((type, index) => (
                          <option key={index} value={type._id}>
                            {type.type_name.charAt(0).toUpperCase() +
                              type.type_name.slice(1)}
                          </option>
                        ))}
                    </select>
                    {review.errors["attraction_type"] &&
                      renderErrorMessage(review.errors["attraction_type"])}
                  </div>
                </div>
              </div>

              {review.attraction_type && (
                <>
                  {/* <div className="card-styling">
                <div className="stepper-search-input">
                  <div className="stepper-search-country">
                    <label>
                      What is the country name in which{" "}
                      {review.attraction_type.type_name
                        .charAt(0)
                        .toUpperCase() +
                        review.attraction_type.type_name.slice(1)}{" "}
                      is situated?
                      {review.errors["country"] && (
                        <span className="error-asterisk">*</span>
                      )}
                    </label>
                    <div>
                      <img src={country} className="country-img" alt="country" />
                    </div>
                  </div>
                  <div className="dropdown-container  ">
                    <select
                      className="dropdown"
                      value={review.country._id}
                      onChange={(e) =>
                        handleChange(
                          index,
                          "country",
                          countries.find(
                            (country) => country._id === e.target.value
                          )
                        )
                      }
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country._id} value={country._id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {review.errors["country"] &&
                      renderErrorMessage(review.errors["country"])}
                  </div>
                </div>
              </div> */}
                  <div className="card-styling">
                    <div className="stepper-search-input">
                      <div
                        className="stepper-search-country"
                        style={{ marginBottom: "10px" }}>
                        <label>
                          What is the country name in which{" "}
                          {review.attraction_type.type_name
                            .charAt(0)
                            .toUpperCase() +
                            review.attraction_type.type_name.slice(1)}{" "}
                          is situated?
                          {review.errors["country"] && (
                            <span className="error-asterisk">*</span>
                          )}
                        </label>
                        <div>
                          <img
                            src={country}
                            className="country-img"
                            alt="country"
                            onError={(e) => (e.target.src = country)}
                          />
                        </div>
                      </div>
                      <div className="App">
                        <header className="App-header">
                          <div
                            className="dropdown-autocomplete"
                            style={{ position: "relative" }}>
                            <ReactSearchAutocomplete
                              items={
                                countries.length > 0
                                  ? countries.map((country) => ({
                                      ...country,
                                      id: country._id,
                                    }))
                                  : []
                              }
                              value={searchValue}
                              onChange={(value) => setSearchValue(value)}
                              inputSearchString={searchValue}
                              // onSearch={handleOnSearch}
                              // onHover={handleOnHover}
                              onSelect={(item) => handleOnSelect(index, item)}
                              onFocus={handleOnFocus}
                              showIcon={false}
                              autoFocus
                              formatResult={formatResult}
                              styling={{
                                zIndex: 1000,
                              }}
                            />
                            {searchValue && (
                              <BsX
                                className="clear-search-icon"
                                onClick={clearSearch}
                              />
                            )}
                          </div>
                        </header>
                      </div>
                    </div>
                  </div>

                  <div className=" card-styling">
                    <div className="stepper-search-input">
                      <label>
                        What is{" "}
                        {review.attraction_type.type_name
                          .charAt(0)
                          .toUpperCase() +
                          review.attraction_type.type_name.slice(1)}
                        's name?
                        {review.country && !review.country._id && (
                          <span className="error-message">
                            (Please select a country first)
                          </span>
                        )}
                      </label>
                      <AutoCompleteSearchBoxForReview
                        handleChangeName={handleChange}
                        attractionAddress={review?.title}
                        countryCodeSelected={review?.country?.short_name}
                        isTitle={true}
                        index={index}
                        setIsAttractionFind={setIsAttractionFind}
                        isAttractionFind={isAttractionFind}
                      />
                      {review.errors["title"] &&
                        renderErrorMessage(review.errors["title"])}
                    </div>
                  </div>
                  {!isAttractionFind && review.title.length > 3 && (
                    <div className=" card-styling">
                      <div className="stepper-search-input">
                        <div className="stepper-search-country">
                          <label>
                            What is{" "}
                            {review.attraction_type.type_name
                              .charAt(0)
                              .toUpperCase() +
                              review.attraction_type.type_name.slice(1)}
                            's address?
                          </label>
                          <div>
                            <img
                              src={address}
                              className="country-img"
                              alt="country"
                              onError={(e) =>
                                (e.target.src =
                                  "https://media.api-sports.io/football/teams/14834.png")
                              }
                            />
                          </div>
                        </div>

                        <AutoCompleteSearchBoxForReview
                          handleChangeName={handleChange}
                          countryCodeSelected={review?.country?.short_name}
                          attractionAddress={review.address}
                          isTitle={false}
                          index={index}
                          isAttractionFind={isAttractionFind}
                          setIsAttractionFind={setIsAttractionFind}
                        />
                        {review.errors["address"] &&
                          renderErrorMessage(review.errors["address"])}
                      </div>
                    </div>
                  )}
                  {/* Similar inputs for address and country */}
                  <div
                    className="card-2 card-styling"
                    style={{ margin: "38px auto" }}>
                    <div className="">
                      <h4>
                        From 1 to 5, please rank how good is{" "}
                        {review.attraction_type.type_name
                          .charAt(0)
                          .toUpperCase() +
                          review.attraction_type.type_name.slice(1)}
                        ?(Where 5 being the highest)
                      </h4>
                      <div>
                        <StarRatings
                          rating={review.ratings}
                          starDimension="25px"
                          starSpacing="1px"
                          numberOfStars={5}
                          starEmptyColor="#2e2e2e"
                          starRatedColor="#FFDA1A"
                          changeRating={(rating) =>
                            handleReviewChange(index, "ratings", rating)
                          }
                        />
                      </div>
                      {review.errors["ratings"] &&
                        renderErrorMessage(review.errors["ratings"])}
                    </div>
                    <img
                      src={review.attraction_type.icon}
                      alt=""
                      onError={(e) =>
                        (e.target.src = review.attraction_type.icon)
                      }
                    />
                  </div>
                  <div className="card-styling">
                    <div
                      className="card-fourth-1  "
                      style={{ margin: "0 0 30px" }}>
                      <div className="card-four-top">
                        <h4>
                          Share your thoughts about the{" "}
                          {review.attraction_type.type_name
                            .charAt(0)
                            .toUpperCase() +
                            review.attraction_type.type_name.slice(1)}
                          ? (Optional)
                        </h4>
                        <img src={review.attraction_type.icon} alt="" />
                      </div>

                      <div className="card-fourth-section">
                        <textarea
                          type="text"
                          value={review.share_your_thoughts}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "share_your_thoughts",
                              e.target.value
                            )
                          }
                          name="share_your_thoughts"
                          placeholder="Write Something "
                          id=""
                        />
                      </div>
                    </div>
                    <div className="attraction-card-style" />
                  </div>
                </>
              )}
            </div>
          );
        })}
      {/* <div className="review-pubs-button"> */}
      {/* {isCurrentReviewFilled ? (
          <button className="review-pub-btn" onClick={addReview}>
            <IoIosAddCircleOutline />
            REVIEW ANOTHER ATTRACTION
          </button>
        ) : (
          ""
        )} */}
      {/* {reviews.length > 1 && (
          <button
            style={{ backgroundColor: "#ff3535" }}
            className="review-cancel-btn"
            onClick={cancelLastReview}
          >
            CANCEL LAST ATTRACTION
          </button>
        )} */}
      {/* </div> */}
    </div>
  );
};

export default Step1;
