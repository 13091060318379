import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
// import * as Sentry from "@sentry/react";
import moment from "moment";

import Header from "./components/Header";
import {
  fetchLeaguesStart,
  fetchSelectedLeague,
} from "./redux/leagues/leaguesActions";
import {
  fetchUserLocationStart,
  fetchUserLocationSuccess,
  fetchUserLocationFailure,
} from "./redux/userLocation/userLocationActions";
import { getUserLocation } from "./helpers/getUserLocation";
import { fetchMapDetails } from "./redux/map/mapActions";
import { selectFixturesFetching } from "./redux/fixtures/fixturesSelectors";
import "./index.css";
import { fetchDimensions } from "./redux/screenDimensions/screenDimensionsActions";
import {
  fetchFixturesByLocationStart,
  fetchFixturesByLeaguesStart,
} from "./redux/fixtures/fixturesActions";
import { getUrlParams } from "./utils";
import { fetchFormValues } from "./redux/formValues/formValuesActions";
import {
  selectHeight,
  selectWidth,
} from "./redux/screenDimensions/screenDimensionsSelectors";
import MobileAnimation from "./components/MobileAnimation";
import MainRoutes from "./routes";
import { fetchLiveMatchesDate } from "./redux/live/liveActions";
import { selectIsFetchingLiveMatches } from "./redux/live/liveSelectors";
import { initGA } from "./utils/initGA";
import { selectNormalizedLeaguesList } from "./redux/leagues/leaguesSelectors";
import { fetchWishlistRequest } from "./redux/auth/authActions";
import Mixpanel from "./utils/mixPanel";
// import ErrorMessage from "./components/ErrorMessage";
// import mixpanel from "mixpanel-browser";
initGA();
// mixpanel.init("1d9b4d13a615104d6db9075ed84f5192", {
//   debug: true,
//   track_pageview: true,
//   persistence: "localStorage",
// });
// Mixpanel.track('Home screen');
function App({
  fetchLeaguesStart,
  fetchUserLocationStart,
  fetchUserLocationFailure,
  fetchUserLocationSuccess,
  fetchMapDetails,
  fetchDimensions,
  fetchFixturesByLocationStart,
  fetchFormValues,
  height,
  isFetchingLiveMatches,
  width,
  leaguesList,
  isFetchingFixtures,
  fetchSelectedLeague,
  fetchFixturesByLeaguesStart,
  fetchWishlistRequest,
}) {
  useEffect(() => {
    fetchLeaguesStart();
  }, []);
  const { user } = useSelector((state) => state.user);
  const [locationFetched, setLocationFetched] = useState(false);



  // console.log({ BUILD: process.env.BRANCH, all: process.env });
  useEffect(() => {
    const { radius, dates, lat, lng, address, iso_a2, leagues } = getUrlParams(
      window.location.href
    );
    if (radius) {
      let datesArray = [];
      const _format = "YYYY-MM-DD";
      let firstDay = moment(dates.split(",")[0]);
      let lastDate = moment(dates.split(",")[1]).format(_format);
      while (firstDay.format(_format) <= lastDate) {
        datesArray.push(firstDay.format(_format));
        firstDay = firstDay.add(1, "days");
      }
      datesArray = [...datesArray];

      fetchFormValues({
        dates: datesArray,
        currentDate: datesArray[0],
        radius: +radius,
        searchedLocation: { lat: +lat, lng: +lng },
        countryCode: iso_a2,
        address: decodeURIComponent(address).replace(/\+/g, " "),
      });
      if (lat && lng) {
        fetchMapDetails({ center: [+lat, +lng] });
      }
    } else {
      if (leaguesList && leagues) {
        fetchSelectedLeague(leagues.split(",").map((x) => leaguesList[+x]));
      }
    }

    getUserLocation(fetchUserLocationStart)
      .then((res) => {
        fetchUserLocationSuccess(res);
        // console.log(res, "getUserLocation");
        if (!(lat && lng)) {
          fetchMapDetails({ center: res.coordinates });
        }
        setLocationFetched(true);
        Mixpanel.track('User Location permission given');
        // if (!radius && !leagues) {
        //   fetchFixturesByLocationStart(true);
        // } else if (leagues) {
        //   fetchFixturesByLeaguesStart();
        // }
      })
      .catch((err) => {
        const LondonCords = {
          countryCode: "GB",
          coordinates: {
            lat: 51.5073509,
            lng: -0.1277583,
          },
          address: "London, UK",
        };
        Mixpanel.track('User Location permission denied');
        fetchUserLocationSuccess(LondonCords);
        fetchMapDetails({ center: LondonCords.coordinates });
        setLocationFetched(true);
        // fetchFixturesByLocationStart(true);

        // we have had a mistake please try again
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (width !== window.innerWidth) {
        fetchDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    });
  }, []);
  useEffect(() => {
    if (locationFetched && user && user?._id) {
      fetchWishlistRequest(user._id);
    }
  }, [locationFetched, user]);
  return (
    <div
      className="app-container"
      style={{ height: height && width && width <= 1000 ? height : "100vh" }}>
      <div
        className={`${
          (width <= 1000 && isFetchingFixtures) ||
          (width <= 1000 && isFetchingLiveMatches)
            ? "abs"
            : ""
        }`}>
        <Header />

        {/* <Sentry.ErrorBoundary fallback={<ErrorMessage />}> */}
        <MainRoutes />
        {/* </Sentry.ErrorBoundary> */}
      </div>
      {/* {(width <= 1000 && isFetchingFixtures) ||
        (width <= 1000 && isFetchingLiveMatches) ? (
          <MobileAnimation
            height={height}
            width={width}
            isFetchingFixtures={isFetchingFixtures}
          />
        ) : null} */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchLeaguesStart: () => dispatch(fetchLeaguesStart()),
  fetchWishlistRequest: (userId) => dispatch(fetchWishlistRequest(userId)),
  fetchUserLocationStart: () => dispatch(fetchUserLocationStart()),
  fetchUserLocationSuccess: (payload) =>
    dispatch(fetchUserLocationSuccess(payload)),
  fetchUserLocationFailure: (payload) =>
    dispatch(fetchUserLocationFailure(payload)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchDimensions: (payload) => dispatch(fetchDimensions(payload)),
  fetchFixturesByLocationStart: (payload) =>
    dispatch(fetchFixturesByLocationStart(payload)),
  fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
  fetchLiveMatchesDate: (payload) => dispatch(fetchLiveMatchesDate(payload)),
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  fetchFixturesByLeaguesStart: () => dispatch(fetchFixturesByLeaguesStart()),
});

const mapStateToProps = (state) => ({
  height: selectHeight(state),
  width: selectWidth(state),
  isFetchingFixtures: selectFixturesFetching(state),
  isFetchingLiveMatches: selectIsFetchingLiveMatches(state),
  leaguesList: selectNormalizedLeaguesList(state),
});

App.propTypes = {
  isFetchingFixtures: PropTypes.bool,
  fetchLeaguesStart: PropTypes.func,
  fetchWishlistRequest: PropTypes.func,
  fetchUserLocationStart: PropTypes.func,
  fetchSelectedLeague: PropTypes.func,
  fetchLiveMatchesDate: PropTypes.func,
  fetchUserLocationFailure: PropTypes.func,
  fetchUserLocationSuccess: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  fetchFixturesByLeaguesStart: PropTypes.func,
  fetchDimensions: PropTypes.func,
  fetchFixturesByLocationStart: PropTypes.func,
  width: PropTypes.number,
  fetchFormValues: PropTypes.func,
  leaguesList: PropTypes.object,
  isFetchingLiveMatches: PropTypes.bool,
  height: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
