import React, { useContext } from "react";
import { GiSoccerField } from "react-icons/gi";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";

import { selectInternalMap, selectCircle } from "../../redux/map/mapSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import {
  selectSearchBy,
  selectFixturesLeagues,
  selectFixturesList,
  selectSplittedFixtures,
  selectMatches,
} from "../../redux/fixtures/fixturesSelectors";
import { fetchFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import "./showAllMatches.scss";
import {
  selectFixturesForLive,
  selectLiveMatchesDate,
  selectLiveFixturesLeagues,
} from "../../redux/live/liveSelectors";
import { animateCamera } from "../../utils";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { openDetailsSection } from "../../redux/live/liveActions";
import { selectFormValuesCurrentDate } from "../../redux/formValues/formValuesSelectors";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import {
  resetFixtureDetails,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { Context } from "../../helpers/context/Context";
const isMobile =
  /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );
const ShowAllMatches = ({
  fetchMapDetails,
  fetchFilteringValues,
  searchBy,
  deviceLocation,
  openDetailsSection,
  map,
  circle,
  type,
  itemRef,
  fixtures,
  fixturesLeagues,
  route,
  fixturesForLive,
  liveDate,
  splittedFixtures,
  liveFixturesLeagues,
  fetchSidebarDetails,
  currentDay,
  matches,
  fixture,
  resetMatchDetails,
  resetFixtureDetails,
}) => {
  const context = useContext(Context);
  const { isItFiltered } = useSelector((state) => state.filteringValues);
  const insideRadiusFixtures = splittedFixtures?.insideRadiusFixtures;
  // const { insideRadiusFixtures } = splittedFixtures;

  const handleClick = () => {
    context.setIsMarker(false);
    if (itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    resetFixtureDetails();
    fetchFilteringValues({
      isItFiltered: false,
      selectedLeagues:
        route === "home"
          ? Object.values(fixturesLeagues)
          : liveFixturesLeagues[liveDate],
    });

    if (map) {
      if (route === "home") {
        fetchSidebarDetails({ areFixturesDetailsFetched: false });

        fetchMapDetails({ marker: null, markerSelected: false });

        if (searchBy === "leagues" || !insideRadiusFixtures.length) {
          let markers = [...fixtures, { ...deviceLocation }];

          animateCamera(markers, map);
        } else {
          if (circle) {
            map.fitBounds(circle.getBounds(), 0);
          }
        }
      } else {
        openDetailsSection(false);

        if (fixturesForLive && liveDate in fixturesForLive) {
          const fixtures = Object.values(fixturesForLive[liveDate]).flat();
          let markers = [...fixtures, { ...deviceLocation }];
          animateCamera(markers, map);
        }
      }
    }
    // resetMatchDetails();
  };

  if (
    (route === "home" && !fixtures) ||
    (route !== "home" && liveDate in fixturesForLive === false)
  ) {
    return (
      // <div className="show-both-btns">
      <button className="show-map-location">
        <FaMapMarkerAlt color="#5256c8" size={"13px"} />
        Maps by Google
      </button>

      // </div>
    );
  } else {
    return (
      <>
        {/* <div className="show-both-btns"> */}
        {!isItFiltered && !isMobile && (
          <button className="show-map-location">
          <FaMapMarkerAlt color="#5256c8" size={"13px"} />
          Maps by Google
        </button>
        )}
        {fixture && (
          <div
            onClick={handleClick}
            className={`${
              type !== "listButton"
                ? "show-all-matches"
                : "list-show-all-matches"
            }`}>
            <GiSoccerField
              className={`${
                type === "listButton" && "list-show-all-matches-text"
              }`}
              size={type === "listButton" ? 30 : 20}
              color="#2e2e2e"
            />
            <span
              className={`show-all-matches-text ${
                type === "listButton" && "list-show-all-matches-text"
              }`}>
              Show All Matches
            </span>
          </div>
        )}
        {/* </div> */}
      </>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  searchBy: selectSearchBy,
  deviceLocation: selectUserLocationCoords,
  map: selectInternalMap,
  circle: selectCircle,
  fixturesLeagues: selectFixturesLeagues,
  liveFixturesLeagues: selectLiveFixturesLeagues,
  fixtures: selectFixturesList,
  fixturesForLive: selectFixturesForLive,
  liveDate: selectLiveMatchesDate,
  splittedFixtures: selectSplittedFixtures,
  matches: selectMatches,
  currentDay: selectFormValuesCurrentDate,
  fixture: selectFixture,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  openDetailsSection: (payload) => dispatch(openDetailsSection(payload)),
  fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  resetMatchDetails: (payload) => dispatch(resetMatchDetails(payload)),
  resetFixtureDetails: (payload) => dispatch(resetFixtureDetails(payload)),
});

ShowAllMatches.propTypes = {
  fixturesLeagues: PropTypes.object,
  liveFixturesLeagues: PropTypes.object,
  fetchMapDetails: PropTypes.func,
  fetchFilteringValues: PropTypes.func,
  searchBy: PropTypes.string,
  fetchSidebarDetails: PropTypes.func,
  deviceLocation: PropTypes.object,
  map: PropTypes.object,
  openDetailsSection: PropTypes.func,
  splittedFixtures: PropTypes.object,
  circle: PropTypes.object,
  type: PropTypes.string,
  itemRef: PropTypes.object,
  fixtures: PropTypes.array,
  route: PropTypes.string,
  fixturesForLive: PropTypes.object,
  liveDate: PropTypes.string,
  fixture: PropTypes.object,
  resetMatchDetails: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllMatches);
