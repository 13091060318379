//MAIN MODULES
import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { connect, useSelector } from "react-redux";
import moment from "moment";

//ACTIONS,SELECTORS
import {
  selectFormValuesDates,
  selectorSelectDay,
} from "../../redux/formValues/formValuesSelectors";
import {
  fetchFormValues,
  selectDay,
} from "../../redux/formValues/formValuesActions";
import { resetMapDetails } from "../../redux/map/mapActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { resetSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { fetchSelectedLeague } from "../../redux/leagues/leaguesActions";
import {
  fetchFixturesByLocationStart,
  resetFixtures,
  setFixture,
  setLeagues,
  resetMobileFixture,
} from "../../redux/fixtures/fixturesActions";
import {
  getFilteringValues,
  getSortingvalue,
  selectMatches,
} from "../../redux/fixtures/fixturesSelectors";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
// STYLES
import "./index.scss";
import { Context } from "../../helpers/context/Context";
import { selectFilterModalSelectedLeaguesIds } from "../../redux/filteringValues/filteringValuesSelectors";
import { filterFixtures } from "../../helpers/filterFixtures";
import { fetchUserLocation } from "../../redux/userLocation/userLocationActions";
import Mixpanel from "../../utils/mixPanel";
import { selectInternalMap } from "../../redux/map/mapSelectors";
const MatchesDates = ({
  fetchFixturesByLocationStart,
  resetFixtureDetails,
  resetMapDetails,
  fetchSelectedLeague,
  fetchFormValues,
  selectDay,
  matches,
  setFixture,
  setLeagues,
  dates,
  currentSelectDay,
  fetchSidebarDetails,
  resetMobileFixture,
  sortingValues,
  filteringValues,
  selectedLeaguesIds,
  isShow,
  fetchMapDetails,
  fetchUserLocation,
  map
}) => {
  const screen = useSelector((state) => state.screenDimensions);
  const leagueData = useSelector((state) => state.leagues);
  const isMobileScreen = screen.width <= 390;

  const slickRef = useRef(null);
  const context = useContext(Context);
  // console.log("Context", Context);
  const timeoutRef = useRef(null);
  const settings = {
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    touchThreshold: 10,
  };
  const hasSelectedOptions = (league) => {

    return (
      league?.selectedTeams?.length > 0 ||
      league?.selectedStadiums?.length > 0 ||
      league?.selectedLeagues?.length > 0
    );
  };
  const searchOptions = hasSelectedOptions(leagueData);
  const datesList = useCallback(
    (fixtures) => {
      const { isItFiltered } = filteringValues;
      const { insideRadiusFixtures, outsideRadiusFixtures } = fixtures;

      let mainFixturesList = !context.isAllCountries
        ? insideRadiusFixtures || []
        : (insideRadiusFixtures || []).concat(outsideRadiusFixtures || []);

      if (isItFiltered) {
        mainFixturesList = filterFixtures(mainFixturesList, selectedLeaguesIds);
      }

      return mainFixturesList.filter((x) => x?.lat && x?.lng && x?.venue);
    },
    [context.isAllCountries, filteringValues, selectedLeaguesIds]
  );

  const handleLeaguesMap = (date)=>{
    const firstDayFixtures = [
      ...matches[date].insideRadiusFixtures,
      ...matches[date].outsideRadiusFixtures,
    ];
  
    const markers = firstDayFixtures
      .filter(fixture => fixture.venue && fixture.location)
      .map(fixture => ({
        lat: fixture?.location?.lat,
        lng: fixture?.location?.lng,
      }));
  
    // markers.push({ ...deviceLocation });
  
    const bounds = markers.reduce((bounds, marker) => {
      return bounds.extend(marker);
    }, new window.google.maps.LatLngBounds());
  
    setTimeout(() => {
      if (map) {
        map.fitBounds(bounds);
      }
    }, 0);
  
  }

  const handleDateClick = (currentDate) => {
    Mixpanel.track(`Changing Dates  ${currentDate}`);
    fetchUserLocation({ isFetchingOnDate: true });
    resetMapDetails();
    resetFixtureDetails();
    resetFixtures();
    context.setIsFirstTime(true);
    fetchFormValues(currentDate);
    setFixture(matches[currentDate] || null);
    setLeagues(matches[currentDate]?.leagues || null);
    fetchSidebarDetails({ areFixturesDetailsFetched: false });
    resetMobileFixture();
    if(searchOptions){
      handleLeaguesMap(currentDate);
    }
    context.setMobileDateChange(!context.mobileDateChange);
    fetchMapDetails({ markerSelectedOnHover: null });
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      fetchUserLocation({ isFetchingOnDate: false });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const findFirstDateWithMatches = () => {
    const dateWithMatches = dates.find((dd) => {
      const mainFixturesList = datesList(matches[dd] || {});
      return mainFixturesList.length > 0;
    });

    if (dateWithMatches) return dateWithMatches;

    const today = moment().format("YYYY-MM-DD");
    return dates.includes(today) ? today : dates[0];
  };

  const [initialDate, setInitialDate] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // Store index of the selected date

  // useEffect(() => {
  //   if (currentSelectDay) {
  //     const index = dates.findIndex((date) => date === currentSelectDay);
  //     console.log("common firstDateWithMatches use", currentSelectDay, index);
  //     setSelectedIndex(index);
  //   }
  // }, [currentSelectDay, isShow]);

  useEffect(() => {
    if (isMobileScreen) {
      if (!currentSelectDay) {
        const firstDateWithMatches = findFirstDateWithMatches();
        setInitialDate(firstDateWithMatches);
        const index = dates.findIndex((date) => date === firstDateWithMatches);
        // console.log(
        //   "common firstDateWithMatches mobile",
        //   firstDateWithMatches,
        //   index
        // );
        setSelectedIndex(index);
      } else {
        const mainFixturesList = datesList(matches[currentSelectDay] || {});
        if (mainFixturesList.length > 0) {
          const firstDateWithMatches = findFirstDateWithMatches();
          setInitialDate(currentSelectDay || firstDateWithMatches);
          const index = dates.findIndex(
            (date) => date === firstDateWithMatches
          );
          // console.log(
          //   "common firstDateWithMatches mobile ELSe",
          //   firstDateWithMatches,
          //   index
          // );
          if(searchOptions){
            handleLeaguesMap(firstDateWithMatches);
          }
          setSelectedIndex(index);
        } else {
          const firstDateWithMatches = findFirstDateWithMatches();
          setInitialDate(firstDateWithMatches);
          const index = dates.findIndex(
            (date) => date === firstDateWithMatches
          );
          if(searchOptions){
            handleLeaguesMap(firstDateWithMatches);
          }
          // console.log(
          //   "common firstDateWithMatches mobile ELSe {}",
          //   firstDateWithMatches,
          //   index
          // );
          setSelectedIndex(index);
        }
      }
    }

    if (!isMobileScreen) {
      const firstDateWithMatches = findFirstDateWithMatches();
      setInitialDate(firstDateWithMatches);
      const index = dates.findIndex((date) => date === firstDateWithMatches);
      // console.log(
      //   "common firstDateWithMatches desktop",
      //   firstDateWithMatches,
      //   index
      // );
      setSelectedIndex(index);
    }
  }, [dates, matches, filteringValues, datesList]);

  useEffect(() => {
    if (initialDate) {
      selectDay(initialDate);
      handleDateClick(initialDate);

      if (slickRef.current && selectedIndex !== -1) {
        // Scroll to the selected date index
        slickRef.current.slickGoTo(selectedIndex);
      }
    }
  }, [initialDate, selectedIndex]);

  useEffect(() => {
    if (selectedIndex) {
      // console.log("commmo selectedIndex", selectedIndex);
      if (slickRef.current && selectedIndex !== -1) {
        // Scroll to the selected date index
        // console.log("commmo selectedIndex scroll", selectedIndex);
        slickRef.current.slickGoTo(selectedIndex);
      }
    }
  }, [selectedIndex, isShow]);

  const list = dates.map((dd, index) => {
    const mainFixturesList = datesList(matches[dd] || {});
    const isDisabled = mainFixturesList.length === 0;

    return (
      <div
        className="date-container"
        key={dd}
        onClick={() => {
          if (!isDisabled) {
            selectDay(dd);
            handleDateClick(dd);
            // Scroll to the clicked date
            // if (slickRef.current) slickRef.current.slickGoTo(index);
          }
        }}>
        <div
          style={{ whiteSpace: "nowrap" }}
          className={`matches-date ${isDisabled ? "disabled" : ""} ${
            dd === currentSelectDay ? "highlight-date" : ""
          }`}>
          {new Date(dd).toDateString() === new Date().toDateString()
            ? "Today"
            : moment(dd).format("MMM DD")}
        </div>
        <div className={`date-count ${isDisabled ? "disabled" : ""}`}>
          {mainFixturesList.length}
        </div>
      </div>
    );
  });

  if (!isShow) {
    return <></>;
  } else {
    return (
      <div className="matches-dates-wrapper">
        <Slider ref={slickRef} {...settings}>
          {list}
        </Slider>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  dates: selectFormValuesDates(state),
  matches: selectMatches(state),
  currentSelectDay: selectorSelectDay(state),
  sortingValues: getSortingvalue(state),
  filteringValues: getFilteringValues(state),
  selectedLeaguesIds: selectFilterModalSelectedLeaguesIds(state),
  map: selectInternalMap(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchFixturesByLocationStart: () => dispatch(fetchFixturesByLocationStart()),
  fetchFormValues: (currentDate) => dispatch(fetchFormValues({ currentDate })),
  fetchMapDetails: (data) => dispatch(fetchMapDetails(data)),
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  resetFixtureDetails: () => dispatch(resetFixtureDetails()),
  resetFixtures: () => dispatch(resetFixtures()),
  resetMapDetails: () => dispatch(resetMapDetails()),
  resetSidebarDetails: () => dispatch(resetSidebarDetails()),
  selectDay: (payload) => dispatch(selectDay(payload)),
  setFixture: (payload) => dispatch(setFixture(payload)),
  setLeagues: (payload) => dispatch(setLeagues(payload)),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  resetMobileFixture: () => dispatch(resetMobileFixture()),
  fetchUserLocation: (data) => dispatch(fetchUserLocation(data)),
});

MatchesDates.propTypes = {
  dates: PropTypes.array,
  fetchFormValues: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  fetchUserLocation: PropTypes.func,
  resetFixtureDetails: PropTypes.func,
  resetFixtures: PropTypes.func,
  resetMapDetails: PropTypes.func,
  resetSidebarDetails: PropTypes.func,
  fetchSelectedLeague: PropTypes.func,
  fetchFixturesByLocationStart: PropTypes.func,
  selectDay: PropTypes.func,
  setFixture: PropTypes.func,
  matches: PropTypes.object,
  isShow: PropTypes.any,
  setLeagues: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchesDates);
