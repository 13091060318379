import { FETCH_SIDEBAR_DETAILS, RESET_SIDEBAR_DETAILS } from "./sidebarTypes";

const INITIAL_STATE = {
  areFixturesFetched: false,
  areFixturesDetailsFetched: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SIDEBAR_DETAILS:
      return { ...state, ...action.payload };
    case RESET_SIDEBAR_DETAILS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
