import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { MdAccessTime } from "react-icons/md";
import { BsCalendar } from "react-icons/bs";
import PropTypes from "prop-types";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaDirections } from "react-icons/fa";
import { trimString } from "../../helpers/trimString";
// import { selectFixturesLeagues } from "../../redux/fixtures/fixturesSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import "./fixturesListItem.scss";
import { Context } from "../../helpers/context/Context";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import {
  selectFormValuesDates,
  selectFormValuesRadius,
  selectFormValuesSearchedLocation,
} from "../../redux/formValues/formValuesSelectors";
import { geocodeByAddress } from "react-places-autocomplete";
import { fetchTravelTime } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { selectTravelTime } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { FAM_API } from "../../constants/constants";
import { addToWishlist, removeFromWishlist } from "../../helpers/getWishlist";
import { showSuccess } from "../ToastMessage";
import { fetchWishlistRequest } from "../../redux/auth/authActions";
import Mixpanel from "../../utils/mixPanel";
import UnlockFeatureModal from "../UnlockFeatureModal";
import { getCookie } from "../../utils/cookies";
const stadiumIcon = "https://cdn-icons-png.flaticon.com/512/88/88961.png";

const FixturesListItem = ({
  fixture,
  callback,
  section,
  // leagues,
  fetchMapDetails,
  type,
  markerSelected,
  marker,
  isDetailsScreen,
  deviceLocation,
  searchedLocation,
  dates,
  radius,
  fetchTravelTime,
  travelTime,
  fetchWishlistRequest,
  isWishlist,
}) => {
  const {
    home_team,
    away_team,
    distance,
    event_date,
    league,
    venue,
    league_id,
    fixture_id,
    location,
    leagueName,
    event_timestamp,
    estimated,
    statusShort,
    venue_id,
  } = fixture || {};
  const { wishlist } = useSelector((state) => state.user);
  const [addWishlist, setAddWishlist] = useState(false);
  const { user } = useSelector((state) => state.user);
  const context = useContext(Context);
  const [unlockFeatureModal , setUnlockFeatureModal ] = useState(false)
  const { lat, lng } = searchedLocation || deviceLocation || {};
  useEffect(() => {
    if (context.isMarker) {
      callback &&
        callback(
          fixture && marker && marker.fixture_id === fixture_id && fixture
        );
    }
  }, [context.isMarker]);

  useEffect(() => {
    const check = wishlist[fixture?.id];
    setAddWishlist(check ? true : false);
  }, [fixture, wishlist]);

  const handleCLickStadium = async (stadiumName) => {
    let countryCode;

    if (window.google && window.google.maps) {
      try {
        const results = await geocodeByAddress(stadiumName);

        results[0].address_components.forEach((x) => {
          if (x.types.includes("country")) {
            countryCode = x.short_name;
          }
        });
      } catch (error) {
        console.error("Error", error);
        return;
      }
    } else {
      console.error("Google Maps API is not available.");
      return;
    }

    const baseUrl = window.location.origin;
    const path = `/search?radius=${radius}&dates=${dates[0]},${
      dates[dates.length - 1]
    }&lat=${fixture?.lat}&lng=${fixture?.lng}&address=${encodeURIComponent(
      stadiumName
    )}&iso_a2=${countryCode}`;
    window.open(baseUrl + path, "_blank");
  };

  const handleCLickLeague = async (league_id, type = "league") => {
    if (isDetailsScreen) {
      if (type === "league") {
        const baseUrl = window.location.origin;
        const path = `/search?radius=${radius}&dates=${dates[0]},${
          dates[dates.length - 1]
        }&lat=${fixture?.lat}&lng=${fixture?.lng}&leagueId=${league_id}`;
        window.open(baseUrl + path, "_blank");
      }
      if (type === "team") {
        const baseUrl = window.location.origin;
        const path = `/search?radius=${radius}&dates=${dates[0]},${
          dates[dates.length - 1]
        }&lat=${fixture?.lat}&lng=${fixture?.lng}&teamId=${league_id}`;
        window.open(baseUrl + path, "_blank");
      }
      if (type === "stadium") {
        const baseUrl = window.location.origin;
        const path = `/search?radius=${radius}&dates=${dates[0]},${
          dates[dates.length - 1]
        }&lat=${fixture?.lat}&lng=${fixture?.lng}&stadiumId=${league_id}`;
        window.open(baseUrl + path, "_blank");
      }
    }
  };

  const addOrRemoveWishlist = async (id) => {
    Mixpanel.track(`Click on Add/remove on Match list`);
    const authToken = getCookie("token");
    if (!authToken) {
      setUnlockFeatureModal(true);
      return
    }
    const response = await addToWishlist(id);
    if (response?.success) {
      showSuccess(response?.data?.message);
      fetchWishlistRequest(user?._id);
    }
  };

  useEffect(() => {
    if (isDetailsScreen) {
      fetchTravelTime({
        origin: { lat, lng },
        destination: { lat: fixture.lat, lng: fixture.lng },
      });
    }
  }, []);

  return (
    <div
      onMouseEnter={() =>
        fixture?.distance &&
        fetchMapDetails({
          markerSelectedOnHover: fixture,
          matchesToday: context.matchesToday,
        })
      }
      onMouseLeave={() =>
        fixture?.distance && fetchMapDetails({ markerSelectedOnHover: null })
      }
      className="fixture-list-item-container"
      id={`item-${fixture_id}`}>
      <div
        onClick={() => {
          callback && callback(fixture);
        }}
        className={` fixture-list-item  ${
          type !== "detailsSection" && "fixtures-list-item-hover"
        } ${type !== "detailsSection" && "clickable"} ${
          fixture &&
          marker &&
          marker.fixture_id === fixture_id &&
          "selected-fixture-list-item"
        } ${markerSelected && "selected-fixture-list-item"}`}>
        {!isDetailsScreen && (
          <div className="live-matches-league">
            <img
              src={league.logo}
              alt="league-icon"
              onError={(e) => (e.target.src = league.logo)}
            />
            <span>{league.name}</span>
          </div>
        )}
        <div className="logo-block">
          <button
            className="heart-icon clickable"
            onClick={() => addOrRemoveWishlist(fixture?.id)}>
            {!addWishlist ? <FaRegHeart /> : <FaHeart />}
          </button>
          <div className="side-left-col">
            <div
              className={"fixture-team clickable"}
              onClick={() =>{Mixpanel.track(`Click on team on Fixture details`); handleCLickLeague(home_team?.team_id, "team")}}>
              <span className="team-name">{home_team?.name}</span>
              <img
                className="team-logo"
                src={home_team?.logo}
                alt="home team logo"
                onError={(e) => (e.target.src = home_team?.logo)}
              />
            </div>
            {!isWishlist && !isDetailsScreen &&(
              <div className="vs-txt">
                {statusShort === "TBD"
                  ? statusShort
                  : moment(event_timestamp * 1000).format("HH:mm")}
              </div>
            )}

            {(isWishlist ||isDetailsScreen) && (
              <div>
                <div className="vs-txt">
                  {statusShort === "TBD"
                    ? statusShort
                    : moment(event_timestamp * 1000).format("HH:mm")}
                </div>

                <div className="vs-date-txt">
                  {moment(event_timestamp * 1000).format("DD-MM-YYYY")}
                </div>
              </div>
            )}
            <div
              className={"fixture-team__fixture-team-awayTeam clickable"}
              onClick={() =>{Mixpanel.track(`Click on team on Fixture details`); handleCLickLeague(away_team?.team_id, "team")}}>
              <img
                className="team-logo"
                src={away_team?.logo}
                alt="away team logo"
                onError={(e) => (e.target.src = away_team?.logo)}
              />
              <span className="team-name">{away_team?.name}</span>
            </div>
          </div>
        </div>

        {isDetailsScreen ? (
          <div className="team-detail">
            <div className="col-2">
              <div className="side-right-col">
                <div className={"league-block"}>
                  {league?.logo ? (
                    <img
                      src={league.logo}
                      onError={(e) => (e.target.src = league.logo)}
                    />
                  ) : null}
                  <div>
                    <span
                      className="clickable"
                      onClick={() =>{Mixpanel.track(`Click on league on Fixture details`); handleCLickLeague(league.id)}}>
                      {leagueName ? leagueName : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="middle-col">
                <div className="date-time-container">
                  <span className="distance">
                    <FaMapMarkerAlt color="#5256c8" size={"12px"} />
                    <span className="radius" style={{ color: "#5256c8" }}>
                      {distance !== null ? ` ${Math.round(distance)}KM` : "N/A"}
                    </span>
                  </span>
                  {/* 
                <span className="name-container">
                  <span className="date">
                    {location?.venue ? location.venue : ""}
                  </span>
                  {estimated ? <span>*est</span> : null}
                </span> */}
                </div>
              </div>
            </div>
            <div className="stadium-container">
              <div className="stadium">
                <img
                  alt="stadium-icon"
                  src={stadiumIcon}
                  onError={(e) => (e.target.src = stadiumIcon)}
                />
                <span
                  className="clickable"
                  onClick={() =>{Mixpanel.track(`Click on stadium on Fixture details`); handleCLickLeague(venue_id, "stadium")}}>
                  {venue}
                </span>
              </div>
            { travelTime !== "NOT AVAILABLE" && <div className="car-distance">
                Driving time:
                <span>{travelTime}</span>
              </div>}
              <a
                href={`https://www.google.com/maps/dir/'${lat},${lng}'/'${fixture?.lat},${fixture?.lng}'`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={()=> Mixpanel.track(`Click on Directions on Fixture details`)}
                className="directions-btn">
                <button
                  style={{ cursor: "pointer" }}
                  className="directions-btn">
                  <FaDirections size={15} color="white" />
                </button>
              </a>
            </div>
          </div>
        ) : (
          <div className="col-2">
            {isWishlist && (
              <div className="date-time-container">
                <span className="distance">
                  <FaMapMarkerAlt color="#5256c8" size={"13px"} />
                  <span
                    className="radius"
                    style={{ color: "#5256c8", fontSize: 12, fontWeight: 500 }}>
                    {" "}
                    {venue + " " + fixture.city + " " + fixture?.country}
                  </span>
                </span>
              </div>
            )}

            {!isWishlist && (
                  <p className="name-container">
                    <span className="date">{venue ? venue : ""}</span>
                    {estimated ? <span>*est</span> : null}
                  </p>
                  
            )}
            {!isWishlist && (
              <>
                <span className="distance">
                    <FaMapMarkerAlt color="#5256c8" size={"12px"} />
                    <span className="radius" style={{ color: "#5256c8" }}>
                      {distance !== null ? ` ${Math.round(distance)}KM` : "N/A"}
                    </span>
                  </span>
                {section !== "details" ? (
                  <div
                    className={`listItem-info-btn${
                      section !== "details" ? "" : "-hide"
                    }`}>
                    <span className="listItem-info-btn-txt">
                      SUPPORTER INFO
                    </span>
                  </div>
                ) : (
                  <div className={"league-block"}>
                    {league?.logo ? (
                      <img
                        src={league.logo}
                        onError={(e) => (e.target.src = league.logo)}
                      />
                    ) : null}
                    <div>
                      <span>{leagueName ? leagueName : ""}</span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <UnlockFeatureModal unlockFeatureModal={unlockFeatureModal} setUnlockFeatureModal={setUnlockFeatureModal} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  // leagues: selectFixturesLeagues,
  deviceLocation: selectUserLocationCoords,
  searchedLocation: selectFormValuesSearchedLocation,
  dates: selectFormValuesDates,
  radius: selectFormValuesRadius,
  travelTime: selectTravelTime,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchTravelTime: (payload) => dispatch(fetchTravelTime(payload)),
  fetchWishlistRequest: (userId) => dispatch(fetchWishlistRequest(userId)),
});

FixturesListItem.propTypes = {
  fixture: PropTypes.object,
  callback: PropTypes.func,
  fetchWishlistRequest: PropTypes.func,
  section: PropTypes.string,
  leagues: PropTypes.object,
  fetchMapDetails: PropTypes.func,
  type: PropTypes.string,
  markerSelected: PropTypes.bool,
  marker: PropTypes.object,
  isDetailsScreen: PropTypes.bool,
  isWishlist: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixturesListItem);
