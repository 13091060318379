import { createSelector } from "reselect";
import { searchLeagues } from "../../utils";

const selectLeagues = (state) => {
  return state.leagues;
};

export const selectLeaguesList = createSelector(selectLeagues, (leagues) => {
  return leagues.leagues;
});

export const selectFilteredLeaguesList = (inputVal) =>
  createSelector(selectLeaguesList, (leagues) =>
    searchLeagues(leagues, inputVal)
  );

export const selectSelectedLeagues = createSelector(
  selectLeagues,
  (leagues) => {
    console.log("leagues.selectedLeagues", leagues.selectedLeagues);
    return leagues.selectedLeagues;
  }
);

export const selectSelectedTeams = createSelector(selectLeagues, (leagues) => {
  console.log("leagues.selectedTeams", leagues.selectedLeagues);
  return leagues.selectedTeams;
});

export const selectSelectedStadiums = createSelector(
  selectLeagues,
  (leagues) => {
    console.log("leagues.selectedSatdiums", leagues.selectedLeagues);
    return leagues.selectedStadiums;
  }
);

export const selectSelectedLeaguesIds = createSelector(
  selectSelectedLeagues,
  (selectedLeagues) => selectedLeagues.map((x) => x.league_id)
);

export const selectLeaguesFetching = createSelector(
  selectLeagues,
  (leagues) => leagues.isFetching
);

export const selectNormalizedLeaguesList = createSelector(
  selectLeaguesList,
  (leagues) => {
    console.log(leagues, "leagues");
    console.log({ BUILD: process.env.NODE_ENV }, "test ENV");
    console.log(leagues, "leagues end");

    if (leagues.length > 0) {
      let normalizedLeagues = {};
      for (var i = 0; i < leagues?.length; i++)
        if (leagues[i]?.league?.id in normalizedLeagues) {
          normalizedLeagues[leagues[i]?.league.id] = leagues[i];
        } else {
          normalizedLeagues[leagues[i]?.league.id] = leagues[i];
        }
      return normalizedLeagues;
    } else {
      return null;
    }
  }
);
