import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { animated } from "react-spring";
import { RiCloseLine } from "react-icons/ri";
import moment from "moment";

import {
  selectAreFixturesFetched,
  selectAreFixturesDetailsFetched,
  selectIsWishlistFrom,
} from "../../redux/sidebar/sidebarSelectors";
import "./home.scss";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { useMapContentWrapperProps, getUrlParams, retry } from "../../utils";
import {
  selectFixturesFetching,
  selectFixturesError,
  selectDynamicFixturesList,
} from "../../redux/fixtures/fixturesSelectors";
import FilterModal from "../../components/FilterModal";
import SortModal from "../../components/SortModal";
// import Maps from "../../components/Maps";
import MapContent from "../../components/MapContent";
import MobileFixturesList from "../../components/MobileFixturesList";
import { selectIsMobileSidebarVisible } from "../../redux/live/liveSelectors";
import {
  fetchFixturesByLocationFailure,
  resetFixtures,
  fetchFixturesByLocationStart,
  fetchFixturesByLeaguesStart,
  matchSlitByDay,
} from "../../redux/fixtures/fixturesActions";
import {
  fetchSidebarDetails,
  resetSidebarDetails,
} from "../../redux/sidebar/sidebarActions";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails, resetMapDetails } from "../../redux/map/mapActions";
import { resetLiveModals } from "../../redux/live/liveActions";
import Maps from "../../components/Maps";
import { resetFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { GApageView } from "../../utils/GAPageView";
import { Context } from "../../helpers/context/Context";
import {
  fetchSelectedLeague,
  fetchSelectedStadium,
  fetchSelectedTeam,
} from "../../redux/leagues/leaguesActions";
import { selectNormalizedLeaguesList } from "../../redux/leagues/leaguesSelectors";
import MobileFixturesListWishlist from "../../components/MobileFixturesList/MobileFixturesListWishlist";
// const Maps = lazy(() => retry(() => import("../../components/Maps")));

const Home = ({
  areFixturesFetched,
  width,
  isFetchingFixtures,
  areFixturesDetailsFetched,
  isMobileSidebarVisible,
  error,
  fetchFixturesByLocationFailure,
  fetchSidebarDetails,
  fixturesWithCoords,
  resetFilteringValues,
  fetchFixturesByLocationStart,
  fetchFormValues,
  resetFixtureDetails,
  resetSidebarDetails,
  resetLiveModals,
  leaguesList,
  fetchFixturesByLeaguesStart,
  fetchSelectedLeague,
  fetchSelectedStadium,
  fetchSelectedTeam,
  matchSlitByDay,
  fetchMapDetails,
  isWishlistFrom,
}) => {
  const [isMarker, setIsMarker] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [isAllCountries, setIsAllCountries] = useState(true);
  const [matchesToday, setMatchesToday] = useState({});
  const [mobileDateChange, setMobileDateChange] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const itemRef = React.createRef();
  const match = useRouteMatch();
  const { path } = match;
  const { insideRadiusFixtures, outsideRadiusFixtures } = fixturesWithCoords;

  useEffect(() => {
    GApageView("home-web");
  }, []);

  const apiCallFixture = (payload) => {
    setTimeout(() => {
      matchSlitByDay({});
      if (payload) {
        fetchFixturesByLocationStart(payload);
      } else {
        fetchFixturesByLeaguesStart();
      }
    }, 2000);
  };

  useEffect(() => {
    const {
      radius,
      dates,
      lat,
      lng,
      address,
      iso_a2,
      leagueId,
      teamId,
      stadiumId,
    } = getUrlParams(window.location.href);

    if (!path.includes("search")) {
      // resetFixtures();
    } else if (!radius && path.includes("search")) {
      // resetFixtures();
    } else if (path.includes("search")) {
      let datesArray = [];
      const _format = "YYYY-MM-DD";
      let firstDay = moment(dates.split(",")[0]);
      let lastDate = moment(dates.split(",")[1]).format(_format);
      while (firstDay.format(_format) <= lastDate) {
        datesArray.push(firstDay.format(_format));
        firstDay = firstDay.add(1, "days");
      }
      if (datesArray.length === 2 && datesArray[0] === datesArray[1]) {
        datesArray = [firstDay];
      }

      if (leagueId && leaguesList) {
        const selectedLeagues = leaguesList[+leagueId];
        fetchSelectedLeague([selectedLeagues]);
        fetchFormValues({
          currentDate: datesArray.length > 0 && datesArray[0],
          dates: [...datesArray],
          radius: +radius,
          address: "",
          countryCode: "",
          searchedLocation: null,
        });
        apiCallFixture();
      } else if (teamId && !leagueId) {
        fetchSelectedTeam([{ value: teamId }]);
        fetchFormValues({
          currentDate: datesArray.length > 0 && datesArray[0],
          dates: [...datesArray],
          radius: +radius,
          address: "",
          countryCode: "",
          searchedLocation: null,
        });
        apiCallFixture();
      } else if (stadiumId && !leagueId) {
        fetchSelectedStadium([{ value: stadiumId }]);
        fetchFormValues({
          currentDate: datesArray.length > 0 && datesArray[0],
          dates: [...datesArray],
          radius: +radius,
          address: "",
          countryCode: "",
          searchedLocation: null,
        });
        apiCallFixture();
      } else if (!leagueId && !teamId && !stadiumId) {
        fetchFormValues({
          currentDate: datesArray.length > 0 && datesArray[0],
          dates: [...datesArray],
          radius: +radius,
          searchedLocation: { lat: +lat, lng: +lng },
          countryCode: iso_a2,
          address: decodeURIComponent(address).replace(/\+/g, " "),
        });
        apiCallFixture(isAllCountries);
      }
    }

    return () => {
      // resetFixtures();
      resetFixtureDetails();
      resetFilteringValues();
      resetSidebarDetails();
      resetLiveModals();
    };
  }, [window.location.href, leaguesList]);

  console.log(
    "iswishlist check Home",
    areFixturesFetched,
    areFixturesDetailsFetched,
    isWishlistFrom
  );

  return (
    // <Suspense fallback={<Spinner />}>
    <div className={`home testwwwwii`}>
      <Context.Provider
        value={{
          isMarker: isMarker,
          isFirstTime: isFirstTime,
          setIsMarker: setIsMarker,
          isAllCountries: isAllCountries,
          setIsAllCountries: setIsAllCountries,
          matchesToday: matchesToday,
          setMatchesToday: setMatchesToday,
          mobileDateChange: mobileDateChange,
          setMobileDateChange,
          setIsFirstTime: setIsFirstTime,
        }}>
        <animated.div
          style={useMapContentWrapperProps(
            width,
            areFixturesFetched,
            isMobileSidebarVisible,
            "home",
            toggleDrawer
          )}
          className={`mapContent-wrapper`}>
          {width <= 1000 &&
            (insideRadiusFixtures.length > 0 ||
              outsideRadiusFixtures.length > 0) && (
              <RiCloseLine
                className="mobile-close-content"
                onClick={() => {
                  if (error) {
                    fetchFixturesByLocationFailure(null);
                  } else {
                    fetchSidebarDetails({
                      areFixturesFetched: true,
                      areFixturesDetailsFetched: false,
                    });
                    fetchMapDetails({
                      marker: null,
                      markerSelected: false,
                      isMobile: false,
                    });
                  }
                }}
                color="#2e2e2e"
                size={28}
              />
            )}
          <MapContent
            itemRef={itemRef}
            setSortModal={setSortModal}
            setFilterModal={setFilterModal}
            setToggleDrawer={setToggleDrawer}
            toggleDrawer={toggleDrawer}
            isWishlistFrom={isWishlistFrom}
          />
        </animated.div>
        <div
          className={`maps-wrapper ${isFetchingFixtures && "map-overlay"} ${
            isFetchingFixtures && "map"
          }`}>
          <Maps
            itemRef={itemRef}
            type="home"
            setFilterModal={setFilterModal}
            insideRadiusFixtures={insideRadiusFixtures}
            outsideRadiusFixtures={outsideRadiusFixtures}
            isWishlistFrom={isWishlistFrom}
          />
        </div>

        {width <= 1000 && (
          <>
            {isWishlistFrom ? (
              <div className={`mobile-fixtures-list-wrapper`}>
                <MobileFixturesListWishlist
                  setSortModal={setSortModal}
                  setFilterModal={setFilterModal}
                  type="home"
                />
              </div>
            ) : (
              <div
                className={`mobile-fixtures-list-wrapper ${
                  !areFixturesFetched || areFixturesDetailsFetched ? "hide" : ""
                }`}>
                <MobileFixturesList
                  setSortModal={setSortModal}
                  setFilterModal={setFilterModal}
                  type="home"
                />
              </div>
            )}
          </>
        )}
        {/* <SortModal sortModal={sortModal} setSortModal={setSortModal} /> 
      if you need filter by km,date uncomment this code
      */}
        <FilterModal
          type="home"
          filterModal={filterModal}
          setFilterModal={setFilterModal}
        />
      </Context.Provider>
    </div>
    //  </Suspense>
  );
};

const mapStateToProps = (state) => ({
  areFixturesFetched: selectAreFixturesFetched(state),
  areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
  width: selectWidth(state),
  isFetchingFixtures: selectFixturesFetching(state),
  isMobileSidebarVisible: selectIsMobileSidebarVisible(state),
  error: selectFixturesError(state),
  fixturesWithCoords: selectDynamicFixturesList(state),
  leaguesList: selectNormalizedLeaguesList(state),
  isWishlistFrom: selectIsWishlistFrom(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetLiveModals: () => dispatch(resetLiveModals()),
  resetSidebarDetails: () => dispatch(resetSidebarDetails()),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  fetchFixturesByLocationFailure: (payload) =>
    dispatch(fetchFixturesByLocationFailure(payload)),
  resetFixtures: () => dispatch(resetFixtures()),
  fetchFixturesByLocationStart: (payload) =>
    dispatch(fetchFixturesByLocationStart(payload)),
  fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
  resetFixtureDetails: () => dispatch(resetFixtureDetails()),
  resetMapDetails: () => dispatch(resetMapDetails()),
  resetFilteringValues: () => dispatch(resetFilteringValues()),
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  fetchSelectedStadium: (payload) => dispatch(fetchSelectedStadium(payload)),
  fetchSelectedTeam: (payload) => dispatch(fetchSelectedTeam(payload)),
  fetchFixturesByLeaguesStart: () => dispatch(fetchFixturesByLeaguesStart()),
  matchSlitByDay: (payload) => dispatch(matchSlitByDay(payload)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
});

Home.propTypes = {
  areFixturesFetched: PropTypes.bool,
  areFixturesDetailsFetched: PropTypes.bool,
  isWishlistFrom: PropTypes.bool,
  width: PropTypes.number,
  isFetchingFixtures: PropTypes.bool,
  resetSidebarDetails: PropTypes.func,
  resetFilteringValues: PropTypes.func,
  isMobileSidebarVisible: PropTypes.bool,
  error: PropTypes.any,
  fetchSidebarDetails: PropTypes.func,
  fetchFixturesByLocationFailure: PropTypes.func,
  fixturesWithCoords: PropTypes.object,
  resetFixtures: PropTypes.func,
  fetchFixturesByLocationStart: PropTypes.func,
  fetchFormValues: PropTypes.func,
  resetFixtureDetails: PropTypes.func,
  resetMapDetails: PropTypes.func,
  resetLiveModals: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
