/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FiSearch } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import PropTypes from "prop-types";
import "./autoCompleteSearchBox.scss";

const AutoCompleteSearchBoxForReview = ({
  countryCodeSelected,
  attractionAddress,
  handleChangeName,
  isTitle,
  index,
  setIsAttractionFind,
  isAttractionFind,
}) => {
  const [localAddress, setLocalAddress] = useState(attractionAddress);

  const handleChange = (address) => {
    setLocalAddress(address);
    if (isTitle) {
      handleChangeName(index, "title", address);
    }
    if (address.length < 3) {
      setIsAttractionFind(false);
    }
  };
  const handleSelect = (address) => {
    // console.log(address);
    setLocalAddress(address);
    // let countryCode;
    if (window.google && window.google.maps) {
      geocodeByAddress(address)
        .then((results) => {
          if (isTitle) {
            handleChangeName(index, "title", address.split(",")[0]);
            handleChangeName(index, "google_place_Id", results[0].place_id);
            handleChangeName(index, "address", address);
          } else {
            handleChangeName(index, "address", address);
            handleChangeName(index, "google_place_Id", results[0].place_id);
          }
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          if (isTitle) {
            setIsAttractionFind(true);
          }
          handleChangeName(index, "location", latLng);
        })
        .catch((error) => console.error("Error", error));
    } else {
      console.error("Google Maps API is not available.");
    }
  };
  const searchOptions =
    !isAttractionFind && !isTitle
      ? {
          componentRestrictions: { country: countryCodeSelected },
        }
      : {
          types: [
            "restaurant",
            "tourist_attraction",
            "bar",
            "stadium",
            "point_of_interest",
          ],
          componentRestrictions: { country: countryCodeSelected },
        };
  useEffect(() => {
    if (attractionAddress && attractionAddress.length > 0) {
      setLocalAddress(attractionAddress);
    }
  }, [attractionAddress]);
  return (
    <div>
      {!countryCodeSelected && (
        <div className="error-message">Please select a country first.</div>
      )}
      {countryCodeSelected && (
        <PlacesAutocomplete
          value={localAddress}
          onChange={handleChange}
          searchOptions={searchOptions}
          onSelect={handleSelect}
          googleCallbackName="myCallbackFunc1"
        >
          {(
            { getInputProps, suggestions, getSuggestionItemProps, loading },
            i
          ) => (
            <div key={i}>
              <div className="location-search-input-container">
                <FiSearch className="search-icon" size={20} />
                <input
                  {...getInputProps({
                    placeholder: "Search by location",
                    className: "location-search-input",
                  })}
                />

                {localAddress && (
                  <RiCloseLine
                    onClick={() => {
                      setLocalAddress("");
                    }}
                    className="input-close-icon"
                    size={25}
                  />
                )}
              </div>

              <div className="autocomplete-dropdown-container">
                <div className="absolute-list">
                  {loading && <div></div>}

                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  );
};

AutoCompleteSearchBoxForReview.propTypes = {
  countryCodeSelected: PropTypes.string,
  attractionAddress: PropTypes.string,
  handleChangeName: PropTypes.func,
  isTitle: PropTypes.bool,
  index: PropTypes.number,
};

export default AutoCompleteSearchBoxForReview;
