import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-responsive-modal";
import PropTypes from "prop-types";
import { RiCloseLine } from "react-icons/ri";
import { createStructuredSelector } from "reselect";
import { BsCheck } from "react-icons/bs";
import Switch from "react-switch";
import LeaguesList from "../LeaguesList";
import CustomApplyButton from "../CustomApplyButton";
import { connect } from "react-redux";
import {
  fetchFilteringValues,
  fetchIsAllLeaguesChecked,
} from "../../redux/filteringValues/filteringValuesActions";
import {
  selectFilterModalSelectedLeagues,
  selectFilterModalSelectedLeaguesIds,
  selectIsAllLeaguesChecked,
  selectSortBy,
} from "../../redux/filteringValues/filteringValuesSelectors";
import "./filterModal.scss";
import {
  selectLiveFixturesLeagues,
  selectLiveMatchesDate,
} from "../../redux/live/liveSelectors";
import { selectHomeFixturesLeagues } from "../../redux/fixtures/fixturesSelectors";
import SearchLeaguesInput from "../SearchLeaguesInput";
import { Context } from "../../helpers/context/Context";
import Mixpanel from "../../utils/mixPanel";

const FilterModal = ({
  isAllLeaguesChecked,
  setFilterModal,
  filterModal,
  fetchFilteringValues,
  fetchIsAllLeaguesChecked,
  filterModalSelectedLeagues,
  filterModalSelectedLeaguesIds,
  type,
  liveFixtureLeagues,
  liveDate,
  fixturesLeagues,
  sortBy
}) => {
  const context = useContext(Context);
  const matchLeagues =
    type === "home" ? fixturesLeagues : liveFixtureLeagues[liveDate];
  const [selectedLeaguesList, setSelectedLeaguesList] = useState(matchLeagues);
  const [selectedLeaguesListIds, setSelectedLeaguesListIds] = useState(
    matchLeagues?.map((x) => x?.league_id)
  );
  const [inputVal, setInputVal] = useState("");
  const [radiusSwitch, setRadiusSwitch] = useState(false);
  const [sortByRadio, setSortByRadio] = useState(sortBy);

  useEffect(() => {
    if (isAllLeaguesChecked) {
      setSelectedLeaguesList(matchLeagues);
      setSelectedLeaguesListIds(matchLeagues?.map((x) => x?.league_id));
      fetchFilteringValues({ selectedLeagues: matchLeagues });
    } else {
      setSelectedLeaguesList(filterModalSelectedLeagues);
      setSelectedLeaguesListIds(filterModalSelectedLeaguesIds);
    }
  }, [filterModal]);

  useEffect(() => {
    if (matchLeagues) {
      setSelectedLeaguesList(matchLeagues);
      setSelectedLeaguesListIds(matchLeagues.map((x) => x?.league_id));
      fetchFilteringValues({ selectedLeagues: matchLeagues });
    }
  }, [matchLeagues]);

  const handleCountryFilter = (para) => {
    setRadiusSwitch(para);
  };

  const handleClick = () => {
    Mixpanel.track(`Click on Filter Matches`);
    context.setIsAllCountries(!radiusSwitch);
    fetchFilteringValues({
      selectedLeagues: selectedLeaguesList,
      isItFiltered:
        selectedLeaguesList.length === matchLeagues.length ? false : true,
      sortBy:sortByRadio
    });
    setFilterModal(false);
  };

  const handleCheck = (e) => {
    Mixpanel.track(`Click on All leagues filter`);
    fetchIsAllLeaguesChecked({ isAllLeaguesChecked: !isAllLeaguesChecked });
    if (e.target.checked) {
      if (matchLeagues && matchLeagues.length) {
        const ids = matchLeagues.map((x) => x.league_id);
        setSelectedLeaguesList(matchLeagues);
        setSelectedLeaguesListIds(ids);
      }
    } else {
      setSelectedLeaguesList([]);
      setSelectedLeaguesListIds([]);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (checked) => {
    // setIsChecked(checked);
    setRadiusSwitch(checked);
  };
  const handleSortChange = (event) => {
    Mixpanel.track(`Click on Sorting by ${event.target.value}`);
    setSortByRadio(event.target.value);
  };

  return (
    <Modal
      styles={{ modal: { padding: 0, borderRadius: 10 } }}
      showCloseIcon={false}
      open={filterModal}
      onClose={() => setFilterModal(false)}
      center
    >
      <div className="filter-modal">
        <div className="filter-modal-header">
          <RiCloseLine
            onClick={() => setFilterModal(false)}
            className="filter-modal-closeIcon"
            size={25}
            color="#2e2e2e"
          />
          <span className="filter-modal-header-title">Filter & Sort</span>
        </div>

        <div className="filter-modal-content">
          <div className="filterModal-leagueList-container">
            <div className="all-leagues-container">
              <div className="all-leagues-checkbox-wrapper">
                <input
                  className="filterModal-checkbox"
                  type="checkbox"
                  checked={
                    matchLeagues &&
                    selectedLeaguesList?.length === matchLeagues?.length
                      ? true
                      : false
                  }
                  onChange={(e) => handleCheck(e)}
                />
                <div className="show-all-leagues-title">
                  Show All Competitions
                </div>
              </div>
              {/* <div className="toggle-container">
                <div className="toggle-btn">
                  <Switch
                    width={45}
                    height={23}
                    onChange={handleChange}
                    checked={radiusSwitch}
                  />
                </div>
                <p>Only Inside Radius</p>
              </div> */}
            </div>
            <div className="radio-container">
              <div className="radio-one">
                <label htmlFor="radio-time">
                  <input
                    id="radio-time"
                    name="sortRadio"
                    type="radio"
                    value="time"
                    checked={sortByRadio === "time"}
                    onChange={handleSortChange}
                  />
                  <span>Sort by time</span>
                </label>
              </div>
              <div className="radio-one">
                <label htmlFor="radio-distance">
                  <input
                    id="radio-distance"
                    name="sortRadio"
                    type="radio"
                    value="distance"
                    checked={sortByRadio === "distance"}
                    onChange={handleSortChange}
                  />
                  <span>Sort by distance</span>
                </label>
              </div>
              <div className="radio-one">
                <label htmlFor="radio-priority">
                  <input
                    id="radio-priority"
                    name="sortRadio"
                    type="radio"
                    value="priority"
                    checked={sortByRadio === "priority"}
                    onChange={handleSortChange}
                  />
                  <span>Sort by leagues</span>
                </label>
              </div>
            </div>
            <SearchLeaguesInput
              placeholder="Search by country or competition"
              inputVal={inputVal}
              setInputVal={setInputVal}
            />

            <LeaguesList
              route={type}
              inputVal={inputVal}
              type="filterModal"
              selectedLeaguesList={selectedLeaguesList}
              matchLeagues={matchLeagues}
              setSelectedLeaguesList={setSelectedLeaguesList}
              selectedLeaguesListIds={selectedLeaguesListIds}
              setSelectedLeaguesListIds={setSelectedLeaguesListIds}
            />
          </div>
          {/* <div className="show-matches-in">Show matches in: </div> */}
          {/* <div className="country-filter-wrapper">
            <div
              className={`country-filter ${
                radiusSwitch && "selected-country-filter"
              }`}
              onClick={() => {
                handleCountryFilter(true);
              }}
            >
              <div>All Country</div>
              {radiusSwitch ? (
                <div className="checkmark-icon">
                  <BsCheck color="white" size={18} />
                </div>
              ) : null}
            </div>
            <div
              className={`country-filter ${
                !radiusSwitch && "selected-country-filter"
              }`}
              onClick={() => {
                handleCountryFilter(false);
              }}
            >
              <div>Only inside of the Radius</div>
              {!radiusSwitch ? (
                <div className="checkmark-icon">
                  <BsCheck color="white" size={18} />
                </div>
              ) : null}
            </div>
          </div> */}
        </div>
        <div className="modal-custom-apply-button-container">
          <CustomApplyButton text="Apply" handlePress={() => handleClick()} />
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
  fetchIsAllLeaguesChecked: (payload) =>
    dispatch(fetchIsAllLeaguesChecked(payload)),
});

const mapStateToProps = createStructuredSelector({
  filterModalSelectedLeagues: selectFilterModalSelectedLeagues,
  filterModalSelectedLeaguesIds: selectFilterModalSelectedLeaguesIds,
  isAllLeaguesChecked: selectIsAllLeaguesChecked,
  liveFixtureLeagues: selectLiveFixturesLeagues,
  liveDate: selectLiveMatchesDate,
  fixturesLeagues: selectHomeFixturesLeagues,
  sortBy: selectSortBy,
});

FilterModal.propTypes = {
  setFilterModal: PropTypes.func,
  filterModal: PropTypes.bool,
  fetchFilteringValues: PropTypes.func,
  fetchIsAllLeaguesChecked: PropTypes.func,
  filterModalSelectedLeagues: PropTypes.array,
  filterModalSelectedLeaguesIds: PropTypes.array,
  isAllLeaguesChecked: PropTypes.bool,
  type: PropTypes.string,
  liveFixtureLeagues: PropTypes.object,
  liveDate: PropTypes.string,
  fixturesLeagues: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
