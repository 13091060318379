import React, { useState, useEffect } from "react";
import "./authscreen.scss";
import signInImg from "../../assets/images/signin-img.png";
import { useDispatch, useSelector } from "react-redux";
import facebook from "../../assets/images/facebook.svg";
import google from "../../assets/images/google.svg";
import { loginRequest } from "../../redux/auth/authActions";
import { showError } from "../ToastMessage";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthRight } from "../AuthRight/authright";
import Mixpanel from "../../utils/mixPanel";

const AuthScreen = ({ title, btnText }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user);

  useEffect(() => {
    if (data?.user && !data?.error) {
      history.push("/");
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleLoginClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      showError("Invalid email address");
      return;
    }
    
    if (!validatePassword(password)) {
      showError("Password must be at least 6 characters long, include an uppercase letter, a number, and a special character.");
      return;
    }

    Mixpanel.track('User login through inputs');
    dispatch(loginRequest({ email, password }));
  };

  const handleClick = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      Mixpanel.track('Click on login through google');
      dispatch(loginRequest({ token: tokenResponse.code, platform: "google" }));
    },
    onError: (error) => {
      Mixpanel.track(`Error on google login ${String(error)}`);
      showError(String(error));
    },
    scope: "email profile openid",
    flow: "auth-code",
  });

  return (
    <div className="signin-container">
      <div className="sigin-left-section">
        <div className="sigin-left">
          <h2 className="heading">{title}</h2>
          <p>Sign in to continue process</p>
          <div className="auth-container">
            <div className="button-container">
              <button onClick={() => Mixpanel.track(`Click on Facebook Login`)} className="apple-btn">
                <img src={facebook} alt="facebook-icon" className="facebook-icon" />
                <p>{btnText} with Facebook</p>
              </button>
              <button className="google-btn" onClick={handleClick}>
                <img src={google} alt="google-icon" className="google-icon" />
                <p>{btnText} with Google</p>
              </button>
            </div>
            <div className="line">
              <span>or</span>
            </div>
            <div className="input-container">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Email"
                className="email-input"
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="reset-container">
            <p>
              Don't have an account?{" "}
              <span
                className="clickable"
                onClick={() => { Mixpanel.track(`Click on Sign Up`); history.push("/sign-up") }}>
                Sign Up
              </span>
            </p>
            <p
              className="clickable"
              onClick={() => { Mixpanel.track(`Click on Forgot Password`); history.push("/forgot-password") }}>
              Forget Password
            </p>
          </div>
          <button className="login-btn" onClick={handleLoginClick}>
            {btnText}
          </button>
        </div>
      </div>
      <AuthRight title={"Welcome Back"} />
    </div>
  );
};

export default AuthScreen;
