import React from "react";
import "./wishlist.scss";
import { useSelector, useDispatch } from "react-redux";
import FixturesListItems from "../../components/FixturesListItem/FixturesListItems";
import {
  fetchFixture,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { useHistory } from "react-router-dom";
import { setFixture } from "../../redux/fixtures/fixturesActions";
import Mixpanel from "../../utils/mixPanel";
const Wishlist = () => {
  const { wishlisting } = useSelector((state) => state.user);
  const {
    circle,
    internalMap: map,
    markerSelected,
    marker,
  } = useSelector((state) => state.map);
  console.log("circle wishlist", circle);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleItemClick = (fixture) => {
    Mixpanel.track(`Click on Match details on Wishlist`)
    dispatch(resetMatchDetails());
    if (!fixture) {
      return;
    }
    const { lat, lng } = fixture;
    dispatch(
      setFixture({
        insideRadiusFixtures: [fixture],
        outsideRadiusFixtures: [],
        leagues: [fixture.league],
      })
    );
    console.log("fixture", fixture);

    dispatch(
      fetchMapDetails({
        zoom: 13,
        center: { lat, lng },
        marker: fixture,
      })
    );
    dispatch(
      fetchSidebarDetails({
        areFixturesFetched: true,
        areFixturesDetailsFetched: true,
        isWishlistFrom: true,
      })
    );
    dispatch(fetchFixture(fixture));
    // if (!fixture.distance && map) {
    //   map.fitBounds(circle.getBounds(), 0);
    // }
    history.push("/home");
  };
  // console.log(
  //   "State Condition =>",
  //   isFetchingFixtures, // false false false
  //   areFixturesDetailsFetched, // false false true
  //   originalFixturesList,// undefined listall []
  //   areFixturesFetched // false true true
  // );
  return (
    <div className="wishlist">
      <div className="top-heading">
        <h1>Wishlist</h1>
      </div>

      <div className={"wishlist-list"}>
        {wishlisting &&
          wishlisting.length > 0 &&
          wishlisting.sort((a, b) => a.fixture.event_timestamp - b.fixture.event_timestamp).map((wishlist, index) => {
            let fixture = wishlist.fixture;
            return (
              <div className={"wishlist-list-item"} key={index}>
                <FixturesListItems
                  markerSelected={markerSelected}
                  type="listSection"
                  section="fixturesList"
                  callback={(fixture) => handleItemClick(fixture)}
                  fixture={fixture}
                  marker={marker}
                  isDetailsScreen={false}
                  isWishlist={true}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Wishlist;
