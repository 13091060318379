import React, { useState, useEffect } from "react";
import { IoIosMenu } from "react-icons/io";
import { RiCloseLine } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";

import Logo from "../../assets/images/appLogo.png";
import "./header.scss";
import NavigationTabs from "../NavigationTabs";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";

import { LOGIN_SUCCESS } from "../../redux/auth/authTypes";
import { clearCookies, getCookie } from "../../utils/cookies";
import { logout } from "../../redux/auth/authActions";
import axios from "axios";
import { FAM_API } from "../../constants";
import Mixpanel from "../../utils/mixPanel";
const DATA = [
  { title: "Home", id: 1, type: "spa", url: "/" },
  // { title: "Vision", id: 2, url: "/" },
  { title: "Contact Us", id: 4, type: "spa", url: "/contact" },
  { title: "About Us", id: 2, type: "spa", url: "/about-us" },
  // { title: "Wishlist", id: 5, type: "spa", url: "/wishlist" },
  // { title: "All World", id: 5, type: "spa", url: "/live" },
];

const Header = () => {
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);
  const data = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobileScreen =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );

  // console.log(data, "data");

  const resetAction = () => {
    Mixpanel.track('Click on Logo to go Home');
    dispatch({
      type: "RESET_MAP_DETAILS",
    });
    dispatch({
      type: "RESET_FIXTURES",
    });
    history.push(`/`);
  };

  console.log("data?.user", data?.user);

  const name = data?.user?.firstName || data?.user?.lastName || "user";
  useEffect(() => {
    if (!data?.user?._id) {
      getData();
    }
  }, [data.user]);

  const getData = async () => {
    const token = getCookie("token");
    if (token) {
      const response = await axios.get(`${FAM_API}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            ...response.data.data,
            accessToken: token,
          },
        });
      }
    }
  };

  const handleLogout = () => {
    try {
      clearCookies();
      dispatch(logout());
      Mixpanel.track('Click on Logout');
      history.push(`/`);
      setIsOpen(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  console.log("data?.user?.accessToken", data);

  return (
    <div
      className={`header ${dropdown && "header-slided"}`}
      style={
        isMobileScreen && dropdown
          ? {
              height: data?.user?._id ? 260 : 200,
            }
          : {}
      }>
      <div className="logoContainer">
        <img
          className="logo clickable"
          src={Logo}
          alt="Logo"
          onClick={resetAction}
          onError={(e) => (e.target.src = Logo)}
        />
      </div>
      <div className="tabs-container">
        <NavigationTabs
          setDropdown={setDropdown}
          data={DATA}
          dropdown={dropdown}
        />
      </div>
      <div className="profile-dropdown-container">
        {dropdown ? (
          <RiCloseLine
            onClick={() => setDropdown(false)}
            size={35}
            color="#2e2e2e"
          />
        ) : (
          <IoIosMenu
            onClick={() => setDropdown(true)}
            className="header-hamburger"
            size={35}
            color="#2e2e2e"
          />
        )}
      </div>

      <div className="user-container">
        {data?.user ? (
          <div className="profile-dropdown">
            <div className="user-login" onClick={() => setIsOpen(!isOpen)}>
              <FaRegUserCircle className="user-icon" />
              {name}
            </div>
            {/* <div className="profile-dropdown">
              <div className="user-login" onClick={handleLogout}>
                <FaRegUserCircle className="user-icon" />
                {name}
              </div> */}
            {isOpen && (
              <div className="profile-dropdown-content">
                <ul>
                  <li>
                    <Link to="/settings" onClick={() => {Mixpanel.track('Click on Settings'); setIsOpen(!isOpen)}}>
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/wishlist"
                      onClick={() => {
                        Mixpanel.track('Click on WishList');
                        setIsOpen(false);
                      }}>
                      Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => {Mixpanel.track('Click on Journal'); setIsOpen(false)}}>
                      Journal
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={handleLogout}>
                      Log out
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {/* </div> */}
          </div>
        ) : (
          <Link
            to="/sign-in"
            className="header-login"
            onClick={() => {Mixpanel.track('Click on Login'); setIsOpen(false)}}>
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
