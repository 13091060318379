import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "./unlockmodal.scss";
import hiddenFeature from "../../assets/images/hiddenFeature.png";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { connect } from "react-redux";
import { resetFixtures } from "../../redux/fixtures/fixturesActions";
import { resetMapDetails } from "../../redux/map/mapActions";

const UnlockFeatureModal = ({
  unlockFeatureModal,
  setUnlockFeatureModal,
  resetAllFixture,
  resetFixtureDetails,
  resetMapAllDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const onClick = () => {
    resetFixtureDetails();
    resetAllFixture();
    resetMapAllDetails();
    history.push("/sign-in");
  };
  return (
    <Modal
      styles={{ modal: { padding: 0, borderRadius: 10 } }}
      showCloseIcon={false}
      open={unlockFeatureModal}
      onClose={() => setUnlockFeatureModal(false)}
      center
    >
      <div className="unlock-modal">
        <div className="unlock-modal-cont">
          <h1>Unlock Feature</h1>
          <p>Please sign in to access locked features</p>

          <div className="hidden-img-cont ">
            <img src={hiddenFeature} className="hidden-img" />
          </div>

          <div className="btns-container">
            <button className="signin-btn" onClick={() => onClick()}>
              Sign In To Continue
            </button>
            <button
              onClick={() => setUnlockFeatureModal(false)}
              className="close-btn"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetFixtureDetails: () => dispatch(resetFixtureDetails()),
  resetAllFixture: () => dispatch(resetFixtures()),
  resetMapAllDetails: () => dispatch(resetMapDetails()),
});

UnlockFeatureModal.propTypes = {
  resetFixtureDetails: PropTypes.func,
  resetMapAllDetails: PropTypes.func,
  resetAllFixture: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(UnlockFeatureModal);
