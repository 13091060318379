import React from "react";
import StarRatings from "react-star-ratings";
import People from "../../assets/images/testBear.png";
import "./introMessage.scss";
import { useHistory } from "react-router-dom";
import Mixpanel from "../../utils/mixPanel";
const IntroMessage = () => {
  const history = useHistory();
  return (
    <div
      onClick={()=>{Mixpanel.track('Click on help us'); history.push("/review")}}
      className="intro-message"
    >
      <img className="bear-img-backdrop" src={People}  onError={(e) => e.target.src = People} />
      <div className="mess">
        <p className="intro-message-title">Help us and the others</p>

        <div>
          <StarRatings
            rating={5}
            starDimension="15px"
            starSpacing="1px"
            numberOfStars={5}
            starEmptyColor="#2e2e2e"
            starRatedColor="#FFDA1A"
          />
        </div>
      </div>
    </div>
  );
};

export default IntroMessage;
