import React, { useEffect, useState } from "react";
import "./signup.scss";
import signInImg from "../../assets/images/signin-img.png";
import { useDispatch, useSelector } from "react-redux";
import facebook from "../../assets/images/facebook.svg";
import google from "../../assets/images/google.svg";
import { showError } from "../../components/ToastMessage";
import { signupCall } from "../../redux/auth/authServices";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { loginRequest } from "../../redux/auth/authActions";
import { AuthRight } from "../../components/AuthRight/authright";
import Mixpanel from "../../utils/mixPanel";

const AuthScreen = ({ title, btnText }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.user);

  useEffect(() => {
    if (data?.user && !data?.error) {
      history.push("/");
    }
  }, [data]);

  // Validate password
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "confirm") {
      setCpassword(value);
    }
  };

  const handleSignupClick = async () => {
    // Basic validation
    if (firstName.trim() === "") {
      showError("First Name cannot be empty");
      return;
    }
    if (lastName.trim() === "") {
      showError("Last Name cannot be empty");
      return;
    }
    if (email.trim() === "") {
      showError("Email cannot be empty");
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showError("Invalid email address");
      return;
    }
    if (password.trim() === "") {
      showError("Password cannot be empty");
      return;
    }
    if (!validatePassword(password)) {
      showError("Password must be at least 6 characters long, include an uppercase letter, a number, and a special character.");
      return;
    }
    if (cpassword.trim() === "") {
      showError("Confirm Password cannot be empty");
      return;
    }
    if (password !== cpassword) {
      showError("Password & Confirm Password must match.");
      return;
    }

    const payload = {
      firstName,
      lastName,
      email,
      password,
      signupBy: "email",
    };

    const response = await signupCall(payload);
    if (response?.success) {
      Mixpanel.track("Sign Up through Input");
      history.push("/sign-in");
    }
  };

  const handleClick = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      Mixpanel.track("Click on Sign Up through Google");
      dispatch(loginRequest({ token: tokenResponse.code, platform: "google" }));
    },
    onError: (error) => {
      Mixpanel.track(`Error on Sign Up through Google: ${String(error)}`);
      showError(String(error));
    },
    scope: "email profile openid",
    flow: "auth-code",
  });

  return (
    <div className="signin-container">
      <div className="sigin-left-section">
        <div className="sigin-left">
          <h2 className="heading">Sign Up</h2>
          <p>Sign Up to continue process</p>
          <div className="auth-container">
            <div className="button-container">
              <button onClick={() => Mixpanel.track("Click on Sign Up through Facebook")} className="apple-btn">
                <img src={facebook} alt="facebook-icon" className="facebook-icon" />
                <p> Sign Up with Facebook </p>
              </button>
              <button className="google-btn" onClick={handleClick}>
                <img src={google} alt="google-icon" className="google-icon" />
                <p>Sign Up with Google</p>
              </button>
            </div>
            <div className="line">
              <span>or</span>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                className="firstName-input"
              />
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                className="lastName-input"
              />
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Email"
                className="emails-input"
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                placeholder="Password"
                className="pass-input"
              />
              <input
                type="password"
                name="confirm"
                value={cpassword}
                onChange={handleInputChange}
                placeholder="Confirm Password"
                className="confirm-pass-input"
              />
            </div>
          </div>
          <div className="reset-container">
            <p>
              Already have an account?{" "}
              <span
                className="clickable"
                onClick={() => history.push("/sign-in")}>
                Sign In
              </span>
            </p>
          </div>
          <button className="login-btn" onClick={handleSignupClick}>
            Sign Up
          </button>
        </div>
      </div>
      <AuthRight title={"Sign Up"} subTitle="Join now to unlock special features like your personalized User Journal and build your Wishlist!" />
    </div>
  );
};

export default AuthScreen;
