export const FETCH_FIXTURE = "FETCH_FIXTURE";
export const FETCH_ATTRACTION_BY_LOCATION = "FETCH_ATTRACTION_BY_LOCATION";

export const RESET_FIXTURE_DETAILS = "RESET_FIXTURE_DETAILS";

export const FETCH_SUPPORTERS_DETAILS_START = "FETCH_SUPPORTERS_DETAILS_START";
export const FETCH_SUPPORTERS_DETAILS_SUCCESS =
  "FETCH_SUPPORTERS_DETAILS_SUCCESS";

export const FETCH_MATCH_DETAILS_START = "FETCH_MATCH_DETAILS_START";
export const FETCH_MATCH_DETAILS_SUCCESS = "FETCH_MATCH_DETAILS_SUCCESS";
export const RESET_MATCH_DETAILS = "RESET_MATCH_DETAILS";
export const FETCH_STANDINGS_START = "FETCH_STANDINGS_START";
export const FETCH_STANDINGS_SUCCESS = "FETCH_STANDINGS_SUCCESS";
export const FETCH_STADIUM_IMAGES_START = "FETCH_STADIUM_IMAGES_START";
export const FETCH_STADIUM_IMAGES_SUCCESS = "FETCH_STADIUM_IMAGES_SUCCESS";
export const FETCH_SUMMARY_START = "FETCH_SUMMARY_START";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_MATCH_DETAILS_FAIL = "FETCH_MATCH_DETAILS_FAIL";

export const FETCH_TRAVEL_TIME = "FETCH_TRAVEL_TIME";
export const FETCH_TRAVEL_TIME_SUCCESS = "FETCH_TRAVEL_TIME_SUCCESS";
