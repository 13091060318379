import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  createAttractionRecomendation,
  createAttractionRecomendationAction,
  fetchAttractionTypeData,
} from "../../redux/attractions/attractionActions";
import {
  selectAttractionType,
  selectCountries,
  selectCreateRes,
} from "../../redux/attractions/attractionSelectors";
import PropTypes from "prop-types";
import { BsArrowRight, BsX, BsArrowLeft } from "react-icons/bs";
import "./review.scss";
import { IoIosAddCircleOutline } from "react-icons/io";
import Step1 from "../../components/reviewsSteps/step1";
import Step2 from "../../components/reviewsSteps/step2";
import Step3 from "../../components/reviewsSteps/step3";
import { useHistory } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Mixpanel from "../../utils/mixPanel";
const Review = ({
  fetchAttractionTypeData,
  createAttractionRecomendation,
  attractionTypes,
  countriesList,
  attractionRecomendationCreateRes,
}) => {
  const [step, setStep] = useState(1);
  const [reviews, setReviews] = useState([
    {
      attraction_type: "",
      title: "",
      address: "",
      country: "",
      ratings: 0,
      share_your_thoughts: "",
      location: {},
      google_place_Id: "",
      errors: {},
    },
  ]);
  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    history.goBack();
  };
  const [userInfo, setUserInfo] = useState({ user_email: "", user_name: "" });
  const { attractionRecomendationCreateRes: response } = useSelector(
    (state) => state.attraction
  );

  useEffect(() => {
    fetchAttractionTypeData();
  }, []);

  useEffect(() => {
    if (attractionTypes?.attractionTypes && countriesList) {
      getUrlData();
    }
  }, [attractionTypes, countriesList]);

  const getUrlData = async () => {
    try {
      const url = window.location.href;
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      const title = params.get("title");
      const address = params.get("address");
      const type = params.get("type");

      let countryData = null;
      if (address) {
        const results = await geocodeByAddress(address);
        let countryCode = "";

        results[0].address_components.forEach((component) => {
          if (component.types.includes("country")) {
            countryCode = component.short_name;
          }
        });

        countryData = countriesList.find(
          (ele) =>
            String(ele?.short_name || ele?.code).toLowerCase() ===
            String(countryCode).toLowerCase()
        );
      }

      if (countryData) {
        handleChange(0, "country", { ...countryData, id: countryData._id });
      }

      if (type) {
        const attractionType = attractionTypes?.attractionTypes.find(
          (ele) =>
            String(ele.type_name).toLowerCase() === String(type).toLowerCase()
        );
        handleChange(0, "attraction_type", attractionType);
      }

      if (title) {
        handleChange(0, "title", title);
      }

      if (address) {
        handleChange(0, "address", address);
      }
    } catch (err) {
      console.log("An error occurred while processing data:", err);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(attractionRecomendationCreateRes) &&
      attractionRecomendationCreateRes.length > 0
    ) {
      Mixpanel.track(`Review added successFul`)
      setStep(step + 1);
      dispatch(createAttractionRecomendationAction([]));
    } else {
      console.log("some thing went wrong: ", attractionRecomendationCreateRes);
    }
  }, [attractionRecomendationCreateRes]);

  const addReview = () => {
    Mixpanel.track(`Click on add more review`)
    setStep(1);
    setReviews([
      // ...reviews,
      {
        attraction_type: "",
        title: "",
        address: "",
        country: "",
        ratings: 0,
        share_your_thoughts: "",
        location: {},
        google_place_Id: "",
        errors: {},
      },
    ]);
  };
  const cancelLastReview = () => {
    if (reviews.length > 1) {
      setReviews((prevReviews) => prevReviews.slice(0, -1));
    }
  };
  const handleReviewChange = (index, field, value) => {
    const updatedReviews = [...reviews];
    updatedReviews[index][field] = value;
    updatedReviews[index].errors[field] = "";
    setReviews(updatedReviews);
  };

  const handleChange = (index, field, value) => {
    const updatedReviews = [...reviews];
    updatedReviews[index][field] = value;
    updatedReviews[index].errors[field] = "";
    if (field === "country") {
      updatedReviews[index].errors["title"] = "";
    }
    setReviews(updatedReviews);
  };

  const validateForm = () => {
    let isValid = true;
    const updatedReviews = reviews.map((review) => {
      const errors = {};
      if (!review.attraction_type) {
        errors["attraction_type"] = "Atrraction Type is required.";
        isValid = false;
      }
      if (!review.country) {
        errors["country"] = "Country is required.";
        isValid = false;
      }

      if (!review.title) {
        errors["title"] = "Title is required.";
        isValid = false;
      }
      if (!review.address) {
        errors["address"] = "Address is required.";
        isValid = false;
      }
      if (!review.ratings) {
        errors["ratings"] = "Ratings is required.";
        isValid = false;
      }
      return { ...review, errors };
    });
    setReviews(updatedReviews);
    return isValid;
  };
  const handleSubmit = () => {
    Mixpanel.track(`Click on Submit Review for attraction`)
    const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const lat = params.get("lat");
    const lng = params.get("lng");
    const isValid = validateForm();
    if (isValid) {
      const data = reviews.map((review) => ({
        attraction_type: review.attraction_type._id,
        title: review.title,
        address: review.address,
        country: review.country._id,
        ratings: review.ratings,
        share_your_thoughts: review.share_your_thoughts,
        location: {
          lat,
          lng,
        },
        google_place_Id: review.google_place_Id,
        created_by: "user",
      }));
      createAttractionRecomendation({ reviews: data, userInfo });
    }
  };
  const nextStep = () => {
    const isValid = validateForm();
    if (isValid) {
      Mixpanel.track(`Click on next button on review screen`)
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const progress = ((step - 1) / 2) * 100; // Assuming there are 2 steps

  console.log("attractionTypes", attractionTypes);

  return (
    <div className="header-container">
      <div className="reviews-header">
        <BsArrowLeft className="close-btn" onClick={goBack} size={30} />
        <h2>Help Other Supporters</h2>
        <p>
          The aim of the app is to help other supporters to get full match
          experience when they travel for football all over the world.
        </p>
      </div>
      <div className="review-stepper">
        <div>
          <div className="step-progress">
            <div className="step-bar">
              <div
                className={`step-fill step-fill-${step}`}
                style={{ width: `${progress}%` }}></div>
            </div>
            <div className="step-numbers">
              <span
                className={step === 1 ? "active" : step > 1 ? "completed" : ""}>
                Reviews
              </span>
              <span className={step === 2 ? "active" : ""}>
                YOUR INFO{" "}
                <strong className="stepper-optional">(Optional)</strong>
              </span>
            </div>
          </div>
        </div>
        <div className="stepper-section">
          {step === 1 && (
            <Step1
              attractionTypes={attractionTypes?.attractionTypes}
              countries={countriesList}
              reviews={reviews}
              handleReviewChange={handleReviewChange}
              handleChange={handleChange}
              addReview={addReview}
              cancelLastReview={cancelLastReview}
            />
          )}
          {step === 2 && (
            <Step2 userInfo={userInfo} setUserInfo={setUserInfo} />
          )}
          {step === 3 && <Step3 addReview={addReview} />}
          <div className="move-btn">
            {step === 2 && (
              <button className="previous-btn" onClick={prevStep}>
                <BsArrowLeft size={20} />
                PREVIOUS
              </button>
            )}
            {/* {step === 1 && (
            <button className="review-pub-btn" onClick={addReview}>
              <IoIosAddCircleOutline />
              REVIEW ANOTHER ATTRACTION
            </button>
          )} */}
            {step !== 3 && (
              <button
                className={` ${step === 2 ? "both-btn" : "continue-btn"} `}
                onClick={step === 2 ? handleSubmit : nextStep}
                disabled={step === 3}>
                {step === 2 ? "SUBMIT" : "CONTINUE"}
                <BsArrowRight size={20} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  attractionTypes: selectAttractionType(state),
  countriesList: selectCountries(state),
  attractionRecomendationCreateRes: selectCreateRes(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAttractionTypeData: () => dispatch(fetchAttractionTypeData()),
  createAttractionRecomendation: (payload) =>
    dispatch(createAttractionRecomendation(payload)),
});

Review.propTypes = {
  fetchAttractionTypeData: PropTypes.func,
  attractionTypes: PropTypes.array,
  countriesList: PropTypes.array,
  createAttractionRecomendation: PropTypes.func,
  attractionRecomendationCreateRes: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
