import axios from "axios";
import { FAM_API } from "../../constants";
import moment from "moment";
import { useState, useEffect } from "react";

const useFetchAirports = (user, venue, fixture) => {
  const [url, setUrl] = useState("https://www.skyscanner.net/");

  useEffect(() => {
    (async () => {
      try {
        if (!venue.lng && !venue.lat) return;
        const res = await axios.get(
          `${FAM_API}/get-airports?userLocation="${user.lng},${user.lat}"&venueLocation="${venue.lng},${venue.lat}"`
        );
        const { success, data } = res.data;
        if (success) {
          const { userAirport, venueAirport } = data;
          if (venueAirport) {
            let currentDate = moment(fixture?.event_date)
              .subtract(2, "d")
              .format("YYMMDD");
            let afterDate = moment(fixture?.event_date)
              .add(2, "d")
              .format("YYMMDD");

            setUrl(
              `https://www.skyscanner.net/transport/flights/${userAirport[0]?.iata}/${venueAirport[0]?.iata}/${currentDate}/${afterDate}/?adultsv2=2&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=true&inboundaltsenabled=true`
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [fixture]);
  return url;
};

export default useFetchAirports;
