import React, { useRef, useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SliderModal from "../SliderModal/slider-modal";
import Gallery from "react-photo-gallery";
// import footbalOne from "../../assets/images/footbal-1.avif";
// import footbalTwo from "../../assets/images/footbal-2.avif";
// import footbalThree from "../../assets/images/footbal-3.jpg";

import {
  selectStadiumImages,
  selectIsFetchingStadiumImages,
  selectFixture,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import "./stadium-images.scss";
import { fetchStadiumImagesStart } from "../../redux/fixtureDetails/fixtureDetailsActions";
import Spinner from "../Spinner";
import stadiumFallBack from "../../assets/images/stadium.jpeg"
import Slider from "react-slick";
import Mixpanel from "../../utils/mixPanel";

// function columns(containerWidth) {
//   let columns = 2;
//   if (containerWidth >= 500) columns = 2;
//   if (containerWidth >= 900) columns = 2;
//   if (containerWidth >= 1500) columns = 2;
//   return columns;
// }
const StadiumImages = ({
  isFetchingStadiumImages,
  stadiumImages,
  fetchStadiumImagesStart,
  fixture,
}) => {
  const [sliderModal, setSliderModal] = useState(false);

  const settings = {
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    slidestoScroll: 1,
    arrows: true,
    dots: false,
  };

  const handleImgSlider = () => {};

  const imgList =
    stadiumImages &&
    stadiumImages.length > 0 &&
    stadiumImages.map((image, index) => (
      <div
        key={index}
        className="stadium-img"
        onClick={() =>{  Mixpanel.track(`Click on Images For stadium`); setSliderModal(true)}}
      >
        <img
          src={image.src}
          alt={`football ${index + 1}`}
          onError={(e) => (e.target.src = stadiumFallBack)}
        />
      </div>
    ));

  const slickRef = useRef(null);

  useEffect(() => {
    if (!stadiumImages) {
      fetchStadiumImagesStart();
    }
    if (slickRef && slickRef.current) {
      slickRef.current.slickGoTo(0);
    }
  }, [fixture, slickRef]);

  return (
    <div className="stadium-images">
      <div>
        {stadiumImages && stadiumImages.length > 0 ? (
          <div>
            <Slider ref={slickRef} {...settings}>
              {imgList}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </div>
      <SliderModal
        stadiumImages={stadiumImages}
        setSliderModal={setSliderModal}
        sliderModal={sliderModal}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchStadiumImagesStart: () => dispatch(fetchStadiumImagesStart()),
});

const mapStateToProps = createStructuredSelector({
  stadiumImages: selectStadiumImages,
  isFetchingStadiumImages: selectIsFetchingStadiumImages,
  fixture: selectFixture,
});

StadiumImages.propTypes = {
  fetchStadiumImagesStart: PropTypes.func,
  stadiumImages: PropTypes.array,
  isFetchingStadiumImages: PropTypes.bool,
  fixture: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(StadiumImages);
